<template>
  <div>
    <vue-title title="Register - Your Indoor Cycling Solution"></vue-title>
    <section class="login-wrap">
      <div class="container">
        <div class="row">
          <section class="col-md-8 mx-auto">
            <div class="login-box">
              <h3>Create New Account</h3>
              <p
                style="
                  text-transform: uppercase;
                  font-weight: 600;
                  margin-top: 10px;
                "
              >
                Stunning Maps, World-Class Workouts, Daily Events & Prize Money
                Races Free-To-Ride, No Subscription Required
              </p>

              <form @submit.prevent="registerHandler" autocomplete="off">
                <div class="row">
                  <div
                    v-for="(field, i) of schema"
                    :key="i"
                    :class="field.colClass"
                  >
                    <div class="form-group">
                      <label
                        v-if="field.type !== 'consent'"
                        v-text="field.label"
                      ></label>
                      <template v-if="field.type === 'select'">
                        <select
                          v-model="form[field.model]"
                          class="form-control"
                        >
                          <option
                            :value="dd.Id"
                            :key="index"
                            v-for="(dd, index) in dropdowns[field.options]"
                          >
                            {{ dd.Name }}
                          </option>
                        </select>
                      </template>
                      <template v-else-if="field.type === 'date'">
                        <input
                          v-model="form[field.model]"
                          :placeholder="field.label"
                          :type="field.type"
                          class="form-control"
                          autocomplete="chrome-off"
                          :max="giveMaxRangeForDate()"
                        />
                      </template>
                      <template v-else-if="field.type === 'number'">
                        <input
                          v-model="form[field.model]"
                          :placeholder="field.label"
                          :type="field.type"
                          class="form-control"
                          autocomplete="chrome-off"
                          step=".01"
                        />
                      </template>

                      <template v-else-if="field.type === 'consent'">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            oninvalid="this.setCustomValidity('You must agree to the terms and conditions to register')"
                            onchange="this.setCustomValidity('')"
                            :required="field.validation['required']"
                            type="checkbox"
                            v-model="form[field.model]"
                            :id="field.id"
                          />
                          <label
                            class="form-check-label"
                            :for="field.id"
                            v-html="field.consent_text"
                          >
                          </label>
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.model === 'firstname' ||
                          field.model === 'lastname'
                        "
                      >
                        <input
                          v-model="form[field.model]"
                          :placeholder="field.label"
                          :type="field.type"
                          class="form-control"
                          @blur="$v.form[field.model]?.$touch"
                          autocomplete="chrome-off"
                        />
                        <div
                          style="margin-top: 2%"
                          v-if="!$v.form[field.model].validName"
                          class="text-danger"
                        >
                          Non latin characters are not supported
                        </div>
                      </template>

                      <template v-else>
                        <input
                          v-model="form[field.model]"
                          :placeholder="field.label"
                          :type="field.type"
                          class="form-control"
                          autocomplete="chrome-off"
                        />
                      </template>

                      <div
                        v-if="submitted && getError(field.model)"
                        class="text-danger"
                      >
                        {{ field.label }} is required.
                      </div>
                    </div>
                  </div>

                  <div class="text-danger">
                    {{ weightError || heightError }}
                  </div>
                </div>

                <!-- Add reCAPTCHA component -->
                <div class="form-group">
                  <re-captcha
                    :sitekey="sitekey"
                    @change="onRecaptchaChange"
                    ref="recaptcha"
                  ></re-captcha>
                </div>

                <button
                  class="btn-universal"
                  type="submit"
                  :disabled="!recaptchaVerified"
                  :class="{ 'btn-disabled': !recaptchaVerified }"
                >
                  Submit
                </button>
                <button
                  class="btn-universal btn-register-cancel"
                  type="button"
                  @click="$router.push('login')"
                >
                  Cancel
                </button>
              </form>

              <div
                v-if="formErrors.length > 0"
                class="text-danger ml-5 mr-5 mt-3 text-left p-2 pl-3 rounded"
              >
                <p class="m-0" v-for="(error, e) of formErrors" :key="e">
                  {{ error }}
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as dayjs from "dayjs";
import { required, helpers } from "vuelidate/lib/validators";
import "vue-datetime/dist/vue-datetime.css";
import Schema from "@/json/forms/register.json";
import ReCaptcha from "@/components/ReCaptcha.vue";

export default {
  name: "Register",
  components: {
    // datetime: Datetime
    "re-captcha": ReCaptcha,
  },

  data() {
    return {
      submitted: false,
      schema: Schema,
      dropdowns: {
        countries: [],
        gender: [
          { Id: 0, Name: "Male" },
          { Id: 1, Name: "Female" },
        ],
      },
      form: {
        country: 246,
        gender: 0,
      },
      weightError: null,
      heightError: null,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY, // Your site key
      recaptchaVerified: false,
      recaptchaToken: null,
    };
  },

  validations() {
    return {
      form: this.formValidation,
    };
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["registerStatus"]),
    ...mapGetters("common", ["getCountries"]),

    formValidation() {
      const fields = {};
      for (const field of this.schema) {
        if (field.model === "firstname" || field.model === "lastname") {
          fields[field.model] = {
            required,
            validName: (value) =>
              helpers.regex("Name", /^[a-zA-Z0-9_\-. \u00A0-\u024F]*$/)(value),
          };
        } else {
          if (field.validation.required) {
            fields[field.model] = { required };
          } else {
            fields[field.model] = {};
          }
        }
      }
      return fields;
    },

    formErrors() {
      if (this.errors) {
        return Object.values(this.errors);
      }
      return false;
    },
  },

  async mounted() {
    document.body.classList.add("contact");

    if (this.getCountries.length === 0) {
      await this.$store.dispatch("common/getCountries");
    }
    this.dropdowns.countries = this.getCountries;
  },

  destroyed() {
    document.body.classList.remove("contact");
  },

  methods: {
    onRecaptchaChange(data) {
      this.recaptchaVerified = data.verified;
      this.recaptchaToken = data.token;
    },

    giveMaxRangeForDate() {
      var main_data = new Date(
        new Date().setFullYear(new Date().getFullYear() - 16)
      );
      return main_data.getFullYear() + "-01-01";
    },

    async registerHandler() {
      this.submitted = true;
      this.$v?.$touch();
      if (this.$v?.$invalid) return;
      if (this.weightError) return;
      if (this.heightError) return;

      // Check reCAPTCHA
      if (!this.recaptchaVerified) {
        alert("Please complete the reCAPTCHA");
        return;
      }

      this.form.dob = dayjs(this.form.dob).format("YYYY-MM-DD");
      this.form.token = this.recaptchaToken; // Add token to form
      try {
        const res = await this.$store.dispatch("auth/register", this.form);
        if (res && res.status) {
          this.$router.push({ name: "login" });
        }

        // Reset reCAPTCHA on error
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }

        // Reset verification status
        this.recaptchaVerified = false;
        this.recaptchaToken = null;
      } catch (error) {
        // Reset reCAPTCHA on error
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }

        // Reset verification status
        this.recaptchaVerified = false;
        this.recaptchaToken = null;
      }
    },

    getError(field) {
      return this.$v.form[field]?.$invalid;
    },

    validateWeight(gender, weight) {
      this.weightError = null;

      if (gender === 0 && weight < 50) {
        this.weightError = "The weight field must be 50 or more";
      }

      if (gender === 1 && weight < 45) {
        this.weightError = "The weight field must be 45 or more";
      }

      if (weight > 140) {
        this.weightError = "The weight field must be 140 or less";
      }
    },

    validateHeight(height) {
      this.heightError = null;

      if (height < 50 || height > 250) {
        this.heightError = "The height field must be between 50 and 250";
      }
    },
  },

  watch: {
    registerStatus() {
      this.$router.push({ path: "/" });
    },

    "form.weight": {
      deep: true,
      handler: function (nv) {
        this.validateWeight(parseInt(this.form.gender), parseInt(nv));
      },
    },

    "form.height": {
      deep: true,
      handler: function (nv) {
        this.validateHeight(parseInt(nv));
      },
    },

    "form.gender": {
      deep: true,
      handler: function (nv) {
        this.validateWeight(parseInt(nv), parseInt(this.form.weight));
      },
    },
  },
};
</script>

<style scoped>
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #ff9800;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  /*color: #ff9800;*/
}
.btn-register-cancel {
  margin-left: 10px;
}
.text-danger {
  text-align: left;
}
ul.indicator {
  display: block;
  width: 100%;
  text-align: left;
  padding: 2px 19px;
}
ul.indicator li {
  width: 100%;
  text-align: left;
  padding: 2px 2px;
  display: list-item;
  margin: 0 18px;
  font-size: 14px !important;
}
.btn-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #cccccc !important;
  color: #666666 !important;
}
</style>
