<template>
  <div>
    <vue-title title="Login - Your Indoor Cycling Solution"></vue-title>

    <section class="login-wrap">
      <div class="container">
        <div class="row">
          <section class="col-xl-6 col-lg-7 col-md-9 mx-auto">
            <div class="login-box">
              <h3>SIGN IN</h3>
              <span>Using your MyWhoosh account</span>
              <form @submit.prevent="loginHandler()" autocomplete="off">
                <!-- Email field -->
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input
                    type="email"
                    v-model="form.email"
                    required
                    class="form-control"
                    id="email"
                    placeholder="Email Address"
                    autocomplete="chrome-off"
                  />
                  <div
                    v-if="submitted && getError('email')"
                    class="text-danger text-left"
                  >
                    Please enter correct email
                  </div>
                </div>

                <!-- Password field -->
                <div class="form-group">
                  <label for="password">Password</label>
                  <input
                    type="password"
                    v-model="form.password"
                    required
                    class="form-control"
                    id="password"
                    placeholder="Password"
                    autocomplete="chrome-off"
                  />
                  <div
                    v-if="submitted && getError('password')"
                    class="text-danger text-left"
                  >
                    Password must be at least 6 characters
                  </div>
                  <router-link
                    :to="{ name: 'forgetpassword' }"
                    style="
                      float: right;
                      font-size: 12px;
                      color: #2e2e2e;
                      margin-top: 10px;
                    "
                  >
                    Forgot Password?
                  </router-link>
                </div>

                <div style="clear: both"></div>

                <!-- reCAPTCHA component -->
                <re-captcha
                  :sitekey="sitekey"
                  @change="onRecaptchaChange"
                  ref="recaptcha"
                ></re-captcha>

                <button
                  type="submit"
                  :disabled="!recaptchaVerified"
                  :class="[
                    'btn-universal',
                    { 'btn-disabled': !recaptchaVerified },
                  ]"
                  style="margin-top: 15px"
                >
                  Submit
                </button>

                <ul class="list-inline">
                  <li>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.remember"
                        class="custom-control-input"
                        id="customCheck"
                      />
                      <label class="custom-control-label" for="customCheck">
                        Stay signed in
                      </label>
                    </div>
                  </li>
                  <li>
                    <router-link :to="{ name: 'register' }"
                      >Create New Account?</router-link
                    >
                  </li>
                </ul>
              </form>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { email, required, minLength } from "vuelidate/lib/validators";
import ReCaptcha from "@/components/ReCaptcha.vue";

export default {
  name: "Login",
  components: {
    "re-captcha": ReCaptcha,
  },
  data() {
    return {
      form: {
        remember: true,
      },
      submitted: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY, // Your site key
      recaptchaVerified: false,
      recaptchaToken: null,
    };
  },

  validations: {
    form: {
      email: { required, email },
      password: { required, minLength: minLength(6) },
    },
  },

  computed: {
    ...mapGetters("auth", ["loggedIn"]),
    ...mapGetters(["errors"]),
  },

  mounted() {
    document.body.classList.add("contact");
  },

  destroyed() {
    document.body.classList.remove("contact");
  },

  created() {
    // Get the current query parameters from the route
    const queryParams = { ...this.$route.query };

    // Check for the action=query parameter when the component is created
    const action = queryParams.action;

    if (action === "logout") {
      // Dispatch the logout action from the Vuex store
      this.$store.dispatch("auth/logout").then(() => {
        // Redirect to login page with retained query parameters after logout
        this.$router.push({ name: "login", query: queryParams });
      });
    } else {
      // Handle other cases, such as checking for a token
      const token = localStorage.getItem("webToken");
      if (token) {
        // Redirect to new activities with retained query parameters
        this.$router.push({
          name: "new_activities",
          hash: "#profile",
          query: queryParams,
        });
      } else {
        // If no token, log out and redirect to login
        this.$store.dispatch("auth/logout").then(() => {
          // Redirect to login page with retained query parameters
          this.$router.push({ name: "login", query: queryParams });
        });
      }
    }
  },

  methods: {
    onRecaptchaChange(data) {
      this.recaptchaVerified = data.verified;
      this.recaptchaToken = data.token;
    },

    loginHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (!this.recaptchaVerified) {
        alert("Please complete the reCAPTCHA");
        return;
      }

      this.form.token = this.recaptchaToken;

      this.$store
        .dispatch("auth/login", this.form)
        .then((res) => {
          if (this.loggedIn) {
            const redirectTo = localStorage.getItem("redirectTo");
            if (redirectTo) {
              localStorage.removeItem("redirectTo");
              location.href = redirectTo;
              return;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const referer = urlParams.get("referer");
            // Redirect based on referer if present
            if (referer) {
              location.href = referer;
              return;
            }

            this.$router.push({ name: "new_activities", hash: "#profile" });
            return;
          }

          if (res === "key") {
            this.$router.push({ name: "resetpassword" });
            return;
          }

          // Reset reCAPTCHA for security
          if (this.$refs.recaptcha) {
            this.$refs.recaptcha.reset();
          }

          // Reset verification status
          this.recaptchaVerified = false;
          this.recaptchaToken = null;

          return res;
        })
        .catch(() => {
          // Reset reCAPTCHA
          if (this.$refs.recaptcha) {
            this.$refs.recaptcha.reset();
          }
          // Reset verification status
          this.recaptchaVerified = false;
          this.recaptchaToken = null;
        });
    },

    getError(field) {
      return this.$v.form[field].$invalid;
    },
  },
};
</script>

<style scoped>
.text-danger {
  font-size: 12px;
}

.btn-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #cccccc !important;
  color: #666666 !important;
}
</style>
