<template>
  <div>
    <div ref="recaptchaContainer" id="recaptcha-container"></div>
  </div>
</template>

<script>
export default {
  name: "ReCaptcha",
  props: {
    sitekey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      token: null,
    };
  },
  mounted() {
    this.loadRecaptchaScript();
  },
  methods: {
    loadRecaptchaScript() {
      // Remove any existing script tags to avoid conflicts
      const existingScripts = document.querySelectorAll(
        'script[src*="recaptcha"]'
      );
      existingScripts.forEach((script) => script.remove());

      // Define the callback functions globally
      window.onRecaptchaCallback = (token) => {
        this.token = token;
        this.$emit("change", { verified: true, token });
      };

      window.onRecaptchaExpired = () => {
        this.token = null;
        this.$emit("change", { verified: false, token: null });
      };

      // Create container with auto-render attributes
      const container = this.$refs.recaptchaContainer;
      container.innerHTML = ""; // Clear any existing content
      container.classList.add("g-recaptcha");
      container.setAttribute("data-sitekey", this.sitekey);
      container.setAttribute("data-callback", "onRecaptchaCallback");
      container.setAttribute("data-expired-callback", "onRecaptchaExpired");

      // Load reCAPTCHA script
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    },
    reset() {
      if (window.grecaptcha) {
        window.grecaptcha.reset();
        this.token = null;
        this.$emit("change", { verified: false, token: null });
      }
    },
    getToken() {
      return this.token;
    },
  },
};
</script>
